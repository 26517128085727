import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import LivePlayround from "~/components/LivePlayground/LivePlayground";
import Args from "~/components/Args/Args";
import AccessibilityTable from "~/components/AccessibilityTable/AccessibilityTable";
import ComponentStatusBar from "~/components/ComponentStatusBar/ComponentStatusBar";
import ComponentDescription from "~/components/ComponentDescription/ComponentDescription";
import ComponentImages from "~/components/ComponentImages/ComponentImages";
import TypographyExamples from "~/components/TypographyExamples/TypographyExamples";
export const componentName = {
  display: "Body",
  code: "Body",
  parent: "Typography"
};
export const _frontmatter = {
  "menuLabel": "Body",
  "sortOrder": 1
};
const layoutProps = {
  componentName,
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`Body`}</h1>
    <ComponentDescription of={componentName.display} mdxType="ComponentDescription" />
    <ComponentStatusBar of={componentName.display} mdxType="ComponentStatusBar" />
    <hr></hr>
    <ComponentImages component={componentName} mdxType="ComponentImages" />
    <LivePlayround componentName={componentName} exampleChildren="Be a Caesar!" storybookLink="https://caesars-ui-storybook.pages.dev/?path=/docs/components-typography-body--default-md" mdxType="LivePlayround" />
    <h2>{`Usage`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`import { Body } from "@caesars-digital/caesars-ui";
`}</code></pre>
    <h2>{`Body Sizes`}</h2>
    <TypographyExamples component={componentName.code} mdxType="TypographyExamples" />
    <p>{`The `}<inlineCode parentName="p">{`Body`}</inlineCode>{` component has a default setting of `}<inlineCode parentName="p">{`md`}</inlineCode>{`. It also has a default setting for `}<inlineCode parentName="p">{`fg-default`}</inlineCode>{` as the color. If you'd like to change the text color,
you can easily do so by providing a tailwind class in the `}<inlineCode parentName="p">{`color`}</inlineCode>{` prop, such as `}<inlineCode parentName="p">{`tw-text-fg-inverse`}</inlineCode>{`. Alternatively, you can also just set `}<inlineCode parentName="p">{`className`}</inlineCode>{` like
any other React component and apply styles via your own classes. The default rendered HTML element is a `}<inlineCode parentName="p">{`p`}</inlineCode>{` tag, be careful when rendering as other elements
where there might be element level styles applied to the text.`}</p>
    <h2>{`Properties`}</h2>
    <div className="text-size-4 mb-10">
  <span className="text-red-600">*</span> indicates required.
    </div>
    <Args component={componentName} className="mb-20" mdxType="Args" />
    <h2>{`Accessibility`}</h2>
    <AccessibilityTable className="mb-20" mdxType="AccessibilityTable">
  <AccessibilityTable.Principle principle="Percievable" status="Ready">
    The body text component loads quickly and does not affect the overall performance of the website.
  </AccessibilityTable.Principle>
  <AccessibilityTable.Principle principle="Operable" status="Ready">
    The body text component is easy to navigate and interacts well with other elements on the page. The text size, color, and style are easy to read and do not cause strain on the eyes.
  </AccessibilityTable.Principle>
  <AccessibilityTable.Principle principle="Understandable" status="Ready">
    The body text component is clear, concise, and informative. It conveys the purpose of the page or section in a way that is easy for users to understand.
  </AccessibilityTable.Principle>
  <AccessibilityTable.Principle principle="Robust" status="Ready">
    The body text component is consistent with other headings on the sportsbook, ensuring a consistent user experience.
  </AccessibilityTable.Principle>
    </AccessibilityTable>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      